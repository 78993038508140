<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Subject"
      store="subject"
      icon="far fa-fw fa-dot-circle"
      @save-success="
        ({ id }) =>
          $router.push({ name: 'subjects.show', params: { subjectId: id } })
      "
    />
    <subject />
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import subjectModule from "@/store/modules/subject";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { mapCreateViewLifecycleMethods } from "../helpers";
import Subject from "../../components/subject/Subject";

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "subject",
  subjectModule
);
const { mapGetters } = createNamespacedHelpers("subject");

export default {
  name: "subject-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Subject,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
