import crudGetters from "@/store/templates/crud/getters";

export default {
  subject: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  label: state => state.model.label,
  descriptionFallback: state => state.model.descriptionFallback,
  inheritedDescriptionFallback: state =>
    state.model.inheritedDescriptionFallback,
  type: state => state.model.type,
  parent: state => state.model.parent,
  children: state => state.model.children,
  maxOffersPerLead: state => state.model.maxOffersPerLead,
  maxOffersPerLeadPlaceholder: state => {
    const parent = state.subject[state.model.parent];
    const grandParent = state.subject[parent?.parent];

    return `${parent?.maxOffersPerLead ?? grandParent?.maxOffersPerLead ?? ""}`;
  },
  ...crudGetters
};
