<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Subject"
      store="subject"
      icon="far fa-fw fa-dot-circle"
      :tabs="tabs"
      :deletable="hasRolesOrPermissions('subject_delete')"
    />
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import subjectModule from "@/store/modules/subject";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { mapDetailViewLifecycleMethods } from "../helpers";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";

const createViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "subject",
  subjectModule
);
const { mapGetters } = createNamespacedHelpers("subject");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
export default {
  name: "subject-view",
  mixins: [IsValidationRoot, CanValidate],
  components: {
    CrudHeader
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapGetters(["id", "name"]),
    tabs() {
      return [
        {
          name: "Details",
          to: { name: "subjects.show", params: { subjectId: this.id } }
        },
        {
          name: "Questions",
          href: `${process.env.VUE_APP_API_ROOT}/backoffice/collection/subject/${this.id}/question`
        }
      ];
    }
  },
  ...createViewLifecycleMethods
};
</script>
