<template>
  <v-text-field
    prepend-icon="far fa-euro-sign"
    :label="label"
    v-model="internalValue"
    type="number"
    @blur="onBlur"
  />
</template>

<script type="text/babel">
import { isEmpty, isNil } from "lodash";
import { accounting } from "accounting";

const FORMAT_MONEY_SYMBOL = "";
const FORMAT_MONEY_PRECISION = 2;
const FORMAT_MONEY_THOUSAND = "";
const FORMAT_MONEY_DECIMAL = ".";

export default {
  name: "v-currency",
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    internalValue: null
  }),
  watch: {
    value: {
      handler(newValue) {
        this.internalValue = isNil(newValue)
          ? null
          : accounting.formatMoney(
              accounting.unformat(newValue),
              FORMAT_MONEY_SYMBOL,
              FORMAT_MONEY_PRECISION,
              FORMAT_MONEY_THOUSAND,
              FORMAT_MONEY_DECIMAL
            );
      },
      immediate: true
    }
  },
  methods: {
    onBlur() {
      const isNullOrBlank =
        isNil(this.internalValue) || isEmpty(this.internalValue);
      this.$emit(
        "input",
        isNullOrBlank ? null : accounting.unformat(this.internalValue)
      );
    }
  }
};
</script>
