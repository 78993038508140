var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showChild)?_c('table-overview',{attrs:{"title":"Subjects","icon":"far fa-fw fa-dot-circle","store":"subjects","headers":[
    { text: 'Name', value: 'name' },
    { text: 'Type', value: 'type', sortable: false },
    {
      text: 'Details',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'subject_view'
    }
  ],"actions":_vm.actions,"searchable":"","force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('subjects-table-row',{attrs:{"subject-id":item}})}}],null,false,3948086710)}):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }