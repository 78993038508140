<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs2 v-for="(country, index) in subject.countries" :key="index">
        <subject-country :country="country" />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import SubjectCountry from "@/components/subject/SubjectCountry";

const { mapGetters } = createNamespacedHelpers("subject");

export default {
  name: "subject-countries",
  components: { SubjectCountry },
  inject: ["$validator"],
  computed: {
    ...mapGetters(["subject"])
  }
};
</script>
