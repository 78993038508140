<template>
  <v-currency v-model="price" :label="`${country.iso} price`" />
</template>
<script type="text/babel">
import VCurrency from "@/components/generic/input/VCurrency";

export default {
  name: "subject-country",
  components: { VCurrency },
  inject: ["$validator"],
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  computed: {
    price: {
      get() {
        return this.country.leadPrice;
      },
      set(newValue) {
        this.$store.commit("subject/setCountryPrice", {
          countryId: this.country.id,
          price: newValue
        });
      }
    }
  }
};
</script>
