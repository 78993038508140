import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  name: null,
  label: {
    en_gb: null,
    nl_be: null,
    nl_nl: null,
    fr_be: null,
    fr_fr: null,
    de_de: null
  },
  descriptionFallback: {
    en_gb: null,
    nl_be: null,
    nl_nl: null,
    fr_be: null,
    fr_fr: null,
    de_de: null
  },
  countries: [],
  type: "SECTOR",
  parent: null,
  children: []
});

export default () => ({
  ...initialCrudState(initialModel),
  subject: {}
});
