<template>
  <table-overview
    v-if="!showChild"
    title="Subjects"
    icon="far fa-fw fa-dot-circle"
    store="subjects"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'subject_view'
      }
    ]"
    :actions="actions"
    searchable
    force-initial-sort
  >
    <subjects-table-row
      slot="table-row"
      slot-scope="{ item }"
      :subject-id="item"
    />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import SubjectsTableRow from "../../components/subject/SubjectsTableRow";

export default {
  name: "subjects-overview",
  components: {
    SubjectsTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "subjects.create" },
        rolesOrPermissions: "subject_create"
      }
    ]
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  }
};
</script>
