<template>
  <subject />
</template>
<script type="text/babel">
import Subject from "@/components/subject/Subject";

export default {
  name: "subject-detail-tab-view",
  components: { Subject }
};
</script>
