<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      :counter="255"
      data-vv-name="name"
      :error-messages="errors.collect('name')"
      required
    />
    <v-select
      v-model="type"
      v-validate="'required'"
      :items="types"
      label="Type"
      data-vv-name="type"
      :error-messages="errors.collect('type')"
    />
    <v-autocomplete-subject
      v-if="hasParent"
      v-model="parent"
      label="Parent"
      :sector="type === 'SUBSECTOR'"
      :subsector="type === 'JOB'"
      :required="hasParent"
      :disabled="!isCreate"
    />
    <v-autocomplete-subject
      v-if="!isCreate"
      v-model="children"
      label="Children"
      multiple
      :subsector="type === 'SECTOR'"
      :job="type === 'SUBSECTOR'"
      disabled
    />
    <v-text-field
      v-model="maxOffersPerLead"
      v-validate="'numeric'"
      label="max offers per lead"
      :placeholder="maxOffersPerLeadPlaceholder"
      data-vv-name="maxOffersPerLead"
      :error-messages="errors.collect('maxOffersPerLead')"
      name="maxOffersPerLead"
    />
    <v-locale-text-field
      v-model="label"
      v-validate="'required'"
      data-vv-name="label"
      label="Label"
      required
      :always-visible-locales="['nl_be', 'fr_be']"
      :required-locales="['nl_be', 'fr_be']"
      :error-messages="errors.collect('label')"
    />
    <v-locale-text-field
      v-model="descriptionFallback"
      data-vv-name="description_fallback"
      label="Description Fallback"
      :placeholders="inheritedDescriptionFallback"
      large
      :always-visible-locales="['nl_be', 'fr_be']"
      :error-messages="errors.collect('label')"
    />
    <subject-countries />
  </v-container>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteSubject from "./VAutocompleteSubject";
import VLocaleTextField from "../generic/input/VLocaleTextField";
import SubjectCountries from "@/components/subject/SubjectCountries";

const { mapComputed } = createNamespacedHelpers("subject");

export default {
  name: "subject",
  components: {
    SubjectCountries,
    VLocaleTextField,
    VAutocompleteSubject
  },
  inject: ["$validator"],
  data: () => ({
    types: [
      { value: "SECTOR", text: "Sector" },
      { value: "SUBSECTOR", text: "Subsector" },
      { value: "JOB", text: "Job" }
    ]
  }),
  computed: {
    ...mapComputed([
      "id",
      "name",
      "label",
      "descriptionFallback",
      "inheritedDescriptionFallback",
      "type",
      "parent",
      "children",
      "maxOffersPerLead",
      "maxOffersPerLeadPlaceholder"
    ]),
    hasParent() {
      return ["SUBSECTOR", "JOB"].includes(this.type);
    },
    isCreate() {
      return isNil(this.id);
    }
  },
  watch: {
    type() {
      this.parent = null;
    }
  }
};
</script>
